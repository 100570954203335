<template>
  <div class="home container">
    
       <br /><br />
          <h1  data-text="Welcome to the future of sports analytics." class="display-4 fade-in-image"><span>Welcome to the future of sports analytics</span></h1>
 <h4 class="fade-in-image" >Watch, Analyze, Get an Edge.</h4>
<br />

    <div class="img_holder gradient">
      <div class="img_main">
        <div class="holder">
    <img class="  fade-in-image" src="../assets/marketing/main.jpg">
    </div>
      </div>
    </div>

  <br />

 
       <button @click="$router.push('/dashboard')" class=" btn btn-success " >Signup</button> <button @click="$router.push('/dashboard')" class=" btn btn-dark " >Login</button>
 <br /> <br />
   <p><i   class="fas fa-check"></i> Advance video taging and search capabilities. </p>
   <p><i   class="fas fa-check"></i>Analytics tools: drawing, shape creation, and text.</p>
     <p><i   class="fas fa-check"></i> Effective note taking & sharing with marked-up plays.</p>
     <br />
<hr />
         <img hidden style="width:75%; opacity: 0.1; " src="../assets/marketing/football_post2.png">
  <br />
 <br />
  <br />
  
   <div class="row">
   
      <div class="col-md-6 text-center my-auto">
         <h5>Best-in-class note sharing  </h5>
         <p><i   class="fas fa-check"></i> Write private, public, and coach-only notes.</p>
           <p><i   class="fas fa-check"></i> Have players draw directly on plays and instantly share the drawings with coaches. </p>
              <p><i   class="fas fa-check"></i> Gain an edge by having instant group communication on game breakdowns.</p>
   </div>
 
       <div class="col-md-6">
        <div class="">
    <img class="img_holder2" src="../assets/marketing/notes.png">
    </div>
   </div>
   </div>
     <br />
      <br />
   <div class="row">
       <div class="d-none d-md-block col-md-6">
        <div class="img_holder ">
    <img class="img_main" src="../assets/marketing/add_from_SC.png">
    </div>
   </div>
      <div class="col-md-6 text-center my-auto">
         <h5>Quick and easy video importing  </h5>
         <p><i   class="fas fa-check"></i> Instantly pull any recordings from SeasonCast.</p>
           <p><i   class="fas fa-check"></i> Record with any device and upload clips directly into GameTaper.</p>
           <p><i   class="fas fa-check"></i> Split large recordings into sub-clips in seconds.</p>

   </div>
     <div class="d-md-none ">
        <div class="img_holder ">
    <img class="img_main" src="../assets/marketing/add_from_SC.png">
    </div>
   </div>
   </div>
     <br /> 
 <br /> 

   <h5>Our All-Inclusive Team Pricing</h5>
   <hr />
      <div class="row">
         <div class="d-none d-md-block col-6 text-center">
        
        <div class="pricing_free">
         <h5>Free Team</h5>
        
         <h1>$0</h1>
         <p>/ yearly</p> <hr />
           <p><i   class="fas fa-check"></i> All of our analytics tools </p>
            <p><i   class="fas fa-check"></i> Instant note sharing & team announcements </p>

           <p><i   class="fas fa-check"></i> 25 clips / event </p>
            <p><i   class="fas fa-times"></i> 15 members limit</p>
          <p><i   class="fas fa-times"></i> 4 events limit </p>
          <p><i   class="fas fa-times"></i> Limited support </p>
          <br />
          <button @click="$router.push('/dashboard')" class="btn btn-dark">Try Out</button>
       </div>
         <br /> <br />
   </div>
      <div class="col-12 col-md-6 text-center">
        
        <div class="pricing_box">
         <h5>Elite Team</h5>
        
         <h1>$35</h1>
         <p>/ yearly</p> <hr />
           <p><i   class="fas fa-check"></i> All of our analytics tools </p>
            <p><i   class="fas fa-check"></i> Instant note sharing & team announcements </p>

           <p><i   class="fas fa-check"></i> Unlimited clips </p>
            <p><i   class="fas fa-check"></i> Unlimited members </p>
          <p><i   class="fas fa-check"></i> Unlimited events </p>
          <p><i   class="fas fa-check"></i> Phone & email support </p>
          <br />
          <button @click="$router.push('/dashboard')" class="btn btn-light">Continue</button>
       </div>
         <br /> <br />
   </div>

   </div> 

  
  </div>
</template>

<script>



export default {
  name: 'Home',
  components: {
  
  },
  mounted: async function (){


setInterval(updateGradient,10);

  },

}

var colors = new Array(
  [47, 84, 59],
[36, 145, 72],
    [104, 196, 135],
  [9, 128, 49],
);

var step = 0;
//color table indices for: 
// current color left
// next color left
// current color right
// next color right
var colorIndices = [0,1,2,3];

//transition speed
var gradientSpeed = 0.005;

function updateGradient()
{
  

  
var c0_0 = colors[colorIndices[0]];
var c0_1 = colors[colorIndices[1]];
var c1_0 = colors[colorIndices[2]];
var c1_1 = colors[colorIndices[3]];

var istep = 1 - step;
var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
var color1 = "rgb("+r1+","+g1+","+b1+")";

var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
var color2 = "rgb("+r2+","+g2+","+b2+")";

var objects = document.getElementsByClassName('gradient')
for (let index = 0; index < objects.length; index++) {
  const element = objects[index];
  element.style["background"] = "linear-gradient( to bottom right, "+color1+", "+color2+" )"
}
//  $('#gradient').css({
//    background: "-webkit-gradient(linear, left top, right top, from("+color1+"), to("+color2+"))"}).css({
//     background: "-moz-linear-gradient(left, "+color1+" 0%, "+color2+" 100%)"});
  
  step += gradientSpeed;
  if ( step >= 1 )
  {
    step %= 1;
    colorIndices[0] = colorIndices[1];
    colorIndices[2] = colorIndices[3];
    
    //pick two new target color indices
    //do not pick the same as the current one
    colorIndices[1] = ( colorIndices[1] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
    colorIndices[3] = ( colorIndices[3] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
    
  }
}




</script>
<style>
.pricing_free{
  background-color: #fdfdfd;
   border-radius: 1em;
   text-align: center;
  margin: 0 auto;
color: rgb(53, 53, 53);
padding: 1.3em;
}
.pricing_box{
   background-color: #3cbe84;
   border-radius: 1em;
   text-align: center;
  margin: 0 auto;
color: white;
padding: 1.3em;
}
.holder{
   background-color: #000000;
   border-radius: 1.7em;
}
.img_main{
  width: 100%;
  border-radius: 1.7em;
    background-color: #eeeeee;
    padding: 1em;
}
.img_main img{
    width: 100%;
    height: 100%;
     border-radius: 1.7em;
     background-color: #000000;
}
.img_holder{
  width: 90%;
  padding: 0.5em;


  border-radius: 2em;
  margin: auto;


}
.img_holder2{
  height:600px;
  padding: 2em;


  border-radius: 3em;
  margin: auto;


}
.dashboard{
background-color: #3f9971;
border-radius: 0.4em;
font-weight: 600;
color: white !important;
padding-left: 1em !important;
padding-right: 1em !important;
}
.anim {
    position: relative;
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: calc(20px + 5vw);
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-shadow: 0 0 0.15em #1da9cc;
    user-select: none;
    white-space: nowrap;
    filter: blur(0.007em);
    animation: shake 2.5s linear forwards;
}

.anim span {
 
    -webkit-clip-path: polygon(10% 0%, 44% 0%, 70% 100%, 55% 100%);
            clip-path: polygon(10% 0%, 44% 0%, 70% 100%, 55% 100%);
}

.anim::before,
.anim::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
}

.anim::before {
    animation: crack1 2.5s linear forwards;
    -webkit-clip-path: polygon(0% 0%, 10% 0%, 55% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 10% 0%, 55% 100%, 0% 100%);
}

.anim::after {
    animation: crack2 2.5s linear forwards;
    -webkit-clip-path: polygon(44% 0%, 100% 0%, 100% 100%, 70% 100%);
            clip-path: polygon(44% 0%, 100% 0%, 100% 100%, 70% 100%);
}

@keyframes shake {
    5%, 15%, 25%, 35%, 55%, 65%, 75%, 95% {
        filter: blur(0.018em);
        transform: translateY(0.018em) rotate(0deg);
    }

    10%, 30%, 40%, 50%, 70%, 80%, 90% {
        filter: blur(0.01em);
        transform: translateY(-0.018em) rotate(0deg);
    }

    20%, 60% {
        filter: blur(0.03em);
        transform: translate(-0.018em, 0.018em) rotate(0deg);
    }

    45%, 85% {
        filter: blur(0.03em);
        transform: translate(0.018em, -0.018em) rotate(0deg);
    }

    100% {
        filter: blur(0.007em);
        transform: translate(0) rotate(-0.5deg);
    }
}

@keyframes crack1 {
    0%, 95% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: translate(-51%, -48%);
    }
}

@keyframes crack2 {
    0%, 95% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: translate(-49%, -53%);
    }
}

.fade-in-image {
  animation: fadeIn 6s;
  -webkit-animation: fadeIn 6s;
  -moz-animation: fadeIn 6s;
  -o-animation: fadeIn 6s;
  -ms-animation: fadeIn 6s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

</style>
