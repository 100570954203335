import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/watch/:event_id',
    name: 'Watch',
    component: () => import(/* webpackChunkName: "watch" */ '../views/Watch.vue')
  },
  {
    path: '/add/:event_id',
    name: 'AddClips',
    component: () => import(/* webpackChunkName: "add" */ '../views/AddClips.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/createTeam',
    name: 'createTeam',
    component: () => import(/* webpackChunkName: "createTeam" */ '../views/CreateTeam.vue')
  },
  {
    path: '/joinTeam',
    name: 'joinTeam',
    component: () => import(/* webpackChunkName: "joinTeam" */ '../views/JoinTeam.vue')
  },
  {
    path: '/createEvent/:team_id',
    name: 'createEvent',
    component: () => import(/* webpackChunkName: "AddEvent" */ '../views/AddEvent.vue')
  },
  {
    path: '/updateUser',
    name: 'addUserInfo',
    component: () => import(/* webpackChunkName: "AddUserInfo" */ '../views/AddUserInfo.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/connect/seasoncast/:team_id',
    name: 'seasoncast',
    component: () => import(/* webpackChunkName: "seasoncast" */ '../views/ConnectSeasonCast.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
