import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import firebase from "firebase";


import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

Vue.config.productionTip = false

const configOptions = {
  apiKey: "AIzaSyDabzSOg9cNb6Pw_14mvlUgZB-Sq2ze6Qc",
    authDomain: "gametaper.com",
    projectId: "gametaper-7ac43",
    storageBucket: "bucket-1.gametaper.com",
    messagingSenderId: "95780785383",
    appId: "1:95780785383:web:cef35e1ea5f4be3345a642",
    measurementId: "G-MPV16NRD1E"
};

firebase.initializeApp(configOptions);

firebase.firestore().enablePersistence()
 


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
